import React from 'react';
import Logo from '../../components/Logo';
import Contact from '../../components/Contact';
import withIntl from '../../i18n/withIntl';
import '../../../assets/styles/Web.scss';

const RegistrationGdprConfirm = () => (
  <>
    <section className="Section Section--contact Section--themeLight">
      <div className="Section-main">
        <Logo />
        <h2 className="Section-mainTitle">
          Děkujeme za přihlášení na workshop a potvrzení Vašeho souhlasu s GDPR.
        </h2>
        <p>Na Váš email Vám byly zaslány podklady potřebné k uhrazení registračního poplatku.</p>
      </div>
    </section>
    <footer className="Footer">
      <Contact />
    </footer>
  </>
);

export default withIntl(RegistrationGdprConfirm);
